// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-impact-of-covid-on-eyewear-industry-js": () => import("./../../../src/pages/blog/impact-of-covid-on-eyewear-industry.js" /* webpackChunkName: "component---src-pages-blog-impact-of-covid-on-eyewear-industry-js" */),
  "component---src-pages-blog-the-biggest-eyewear-trends-of-2021-js": () => import("./../../../src/pages/blog/the-biggest-eyewear-trends-of-2021.js" /* webpackChunkName: "component---src-pages-blog-the-biggest-eyewear-trends-of-2021-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lens-simulation-js": () => import("./../../../src/pages/lens-simulation.js" /* webpackChunkName: "component---src-pages-lens-simulation-js" */),
  "component---src-pages-mailer-js": () => import("./../../../src/pages/mailer.js" /* webpackChunkName: "component---src-pages-mailer-js" */),
  "component---src-pages-market-place-js": () => import("./../../../src/pages/market-place.js" /* webpackChunkName: "component---src-pages-market-place-js" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/pos.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-press-kit-js": () => import("./../../../src/pages/press-kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-retailer-js": () => import("./../../../src/pages/retailer.js" /* webpackChunkName: "component---src-pages-retailer-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-virtual-mirror-js": () => import("./../../../src/pages/virtual-mirror.js" /* webpackChunkName: "component---src-pages-virtual-mirror-js" */),
  "component---src-pages-website-building-js": () => import("./../../../src/pages/website-building.js" /* webpackChunkName: "component---src-pages-website-building-js" */),
  "component---src-pages-website-building-marketpage-js": () => import("./../../../src/pages/website-building/marketpage.js" /* webpackChunkName: "component---src-pages-website-building-marketpage-js" */),
  "component---src-pages-wholesaler-js": () => import("./../../../src/pages/wholesaler.js" /* webpackChunkName: "component---src-pages-wholesaler-js" */)
}

